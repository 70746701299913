/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@font-face {
	font-family: 'ranchoregular';
	src:
		url('assets/fonts/rancho-regular-webfont.woff2') format('woff2'),
		url('assets/fonts/rancho-regular-webfont.woff') format('woff'),
		url('assets/fonts/rancho-regular-webfont.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}

// @import "~swiper/swiper-bundle.css";
@import 'swiper/css';
@import 'swiper/css/pagination';

/* template */
/*
@media only screen and (min-width: 38.5em) {
}
@media only screen and (min-width: 68.5em) {
}
@media only screen and (min-width: 82.5em) {
}
*/

.mySwiper {
	width: 100%;
	max-height: 50vh;
	max-width: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	// background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.mainSwiper .swiper-slide img {
	max-height: 48vh;
}

.img-wrapper {
	width: 100%;
	// background-repeat: no-repeat;
	// background-position: center;
	// background-size: cover;
	z-index: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.img-wrapper::before {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: inherit;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: -1;
}

.img-wrapper::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: -1;
	backdrop-filter: blur(8px) brightness(0.8);
	background: radial-gradient(rgb(67 67 67 / 50%), rgb(0 0 0 / 55%));
}

.swiper-slide img {
	// display: block;
	// width: 100%;
	// height: 100%;
	// object-fit: cover;
}

// .mySwiper.thumb {
// 	max-height: 8rem;
// }
.thumb.swiper .swiper-wrapper {
	align-items: center;
}

.swiper-wrapper {
	align-items: center;
}

.thumb.swiper .swiper-wrapper .swiper-slide img {
	border-radius: 5px;
}

.thumb.swiper .swiper-slide {
	padding: 1rem;
}

/* end swiper */

.flow-submit {
	margin: 0 auto;
	display: block;
	max-width: calc(100% - 2rem);
	margin-top: 1rem;
}

.toast-custom {
	font-size: 1rem;
}
// Base toast styles
.toast-custom {
	--min-height: 48px;
	--border-radius: 8px;
	--button-color: var(--ion-color-primary);

	&::part(message) {
		font-size: 14px;
		padding: 12px;
	}

	&::part(button) {
		font-size: 14px;
		padding: 8px 12px;
	}
}

// Shared dark mode properties
@mixin dark-toast-theme {
	--background: rgba(40, 40, 40, 0.95);
	--color: #ffffff;
	--border: 1px solid rgba(255, 255, 255, 0.1);
	--button-color: var(--ion-color-primary-tint);

	&.clickable::part(message) {
		cursor: pointer;
		&:hover {
			background: rgba(255, 255, 255, 0.05);
		}
	}
}

// Shared light mode properties
@mixin light-toast-theme {
	--background: rgba(255, 255, 255, 0.95);
	--color: #333333;
	--border: 1px solid rgba(0, 0, 0, 0.1);
	--button-color: var(--ion-color-primary);

	&.clickable::part(message) {
		cursor: pointer;
		&:hover {
			background: rgba(0, 0, 0, 0.05);
		}
	}
}

// Explicit classes
.toast-dark {
	@include dark-toast-theme;
}

.toast-light {
	@include light-toast-theme;
}

// Media query for system preference
@media (prefers-color-scheme: dark) {
	.toast-custom:not(.toast-light) {
		@include dark-toast-theme;
	}
}

@media (prefers-color-scheme: light) {
	.toast-custom:not(.toast-dark) {
		@include light-toast-theme;
	}
}

.alert-button-role-destructive {
	> .alert-button-inner {
		color: var(--ion-color-danger, red);
	}
}

// Status variations
.toast-custom {
	&.toast-success {
		--background: var(--ion-color-success);
		--color: var(--ion-color-success-contrast);
	}

	&.toast-warning {
		--background: var(--ion-color-warning);
		--color: var(--ion-color-warning-contrast);
	}

	&.toast-error {
		--background: var(--ion-color-danger);
		--color: var(--ion-color-danger-contrast);
	}

	// Position adjustments
	&.toast-top {
		margin-top: env(safe-area-inset-top);
	}

	&.toast-bottom {
		margin-bottom: env(safe-area-inset-bottom);
	}
}
.new-chat-popover .popover-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.new-chat-popover .popover-wrapper .popover-content {
	left: auto !important;
	top: auto !important;
	min-width: 15rem;
}

/* global android checkbox is crowded */
ion-checkbox.md {
	margin-right: 0.5rem;
}

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.data-grid ion-col:first-child {
	padding-left: 0.7rem;
}

.data-grid ion-col:last-child {
	padding-right: 1rem;
}

// .card-border {
//   border-top: 8px solid var(--ion-color-light);
// }
ion-list-header ion-label {
	margin: 0;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

ion-text ion-icon {
	padding-right: 5px;
	position: relative;
	top: 2px;
}

.verifications ion-icon,
.verifications .fas {
	position: absolute;
	top: 8px;
	font-size: 20px;
	margin-left: -15px;
	width: 30px;
	color: #fff;
}

.verifications .fas {
	font-size: 16px;
	top: 9px;
}

.disc {
	width: 30px;
	height: 30px;
	border-radius: 100%;
	overflow: hidden;
	text-align: center;
	position: absolute;
	top: 2px;
	margin-left: -15px;
	left: 50%;
}

.disc.dis {
	background-color: var(--ion-color-medium);
}

.disc.dis.enabled {
	background-color: var(--ion-color-primary);
}

.verHolder {
	display: inline-block;
	margin-left: 7px;
	margin-right: 7px;
	min-width: 30px;
	min-height: 30px;
	position: relative;
}

.verifications .verHolder.small ion-icon,
.verifications .verHolder.small .fas {
	position: absolute;
	top: 6px;
	font-size: 12px;
	margin-left: -5px;
	width: 10px;
	color: #fff;
}

.verifications .verHolder.small .disc {
	width: 20px;
	height: 20px;
	border-radius: 100%;
	overflow: hidden;
	text-align: center;
	position: absolute;
	top: 2px;
	margin-left: -10px;
	left: 50%;
}

.verHolder.small {
	min-width: 15px;
	min-height: 15px;
	margin-left: 5px;
	margin-right: 5px;
}

*[no-padding] {
	padding: 0;
}

ion-icon[small] {
	font-size: 16px;
}

ion-item-divider {
	padding-top: 1.5rem;
}

ion-item.filter-set {
	--inner-padding-end: 0 !important;
	--min-height: 4.1rem !important;
}
